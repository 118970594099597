<template >

   <v-banner
    v-model="v0"
    single-line
    :color="(banner && banner.color)? banner.color: 'primary'"
    elevation="1"
    sticky
    dark
    app
    transition="expand-y-transition"
  >
    <v-icon
      slot="icon"
      color="white"
      size="28"
    >
      mdi-alert-circle-outline
    </v-icon>
    {{banner.title}}

    <template v-slot:actions="{ dismiss }">

      <v-btn
        color="white"
        text
        @click.stop="dialog = true;dismiss"
      >
        {{$t('btns.read')}}
      </v-btn>
    </template>
    <template>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          :color="(banner && banner.color)? banner.color: 'primary'"
        >
          <v-btn
            icon
            dark
            @click="dialog = false; v0 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{banner.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="">
          <v-card class="my-2">
            <v-card-subtitle>{{ $t('contents.banners.lastUpdate', {'date': updateDate })}}</v-card-subtitle>
            <v-card-text>
              <RenderHtml :html="banner.main_content" /> 
            </v-card-text>
          </v-card>
          <v-card v-for="(content, i) in banner.secondary_content" :key="i" class="my-2">
            <v-card-title>{{content.content_title}}</v-card-title>
            <v-card-text>
              <RenderHtml :html="content.content" />  
            </v-card-text>
          </v-card>
          
        </v-card-text>
      </v-card>
    </v-dialog>
    </template>
  </v-banner>
</template>
<script>

import RenderHtml from "@/components/RenderHtml.vue";
import { DateTime } from "luxon";


export default {
    props: ['banner'],
    data: () => ({
      dialog: false,
      v0: true,
    }),
    components: {
      RenderHtml
    },
    computed: {
      updateDate() {
        if(this.$i18n.locale == "fr"){
          return DateTime.fromSQL(String(this.banner.updated_at)).toFormat('dd LLL yyyy');
        }else{
          return DateTime.fromSQL(String(this.banner.updated_at)).toFormat('LLL. dd, yyyy')
        }
      }
    }

    
  }
</script>