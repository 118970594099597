<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="consent"
      persistent
    >
      <v-sheet
        class="text-left pa-3"
      >
        <div class="py-3">
          <h4>{{ $t('title.rgpd') }}</h4>
          <small>{{ $t('contents.rgpd') }}</small>
        </div>
        <v-btn
          class="mt-4 mx-auto white--text"
          
          color="var(--color-primary)"
          @click="enableTracking"
        >
          {{ $t('btns.accept') }}
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
  export default {
    name: 'CookieConsent',
    data () {
      return {
        consent: false
      }
    },
    created () {
      if (localStorage.getItem('disableTracking') == true || localStorage.getItem('disableTracking') == null) {
        this.consent = true
      }
    },
    methods: {
        disableTracking () {
            this.$ga.disable()
            localStorage.disableTracking = true
        },
        enableTracking () {
            this.$ga.enable()
            this.$ga.page(this.$route.fullPath);
            localStorage.disableTracking = false
            this.consent = false
        },
        
    }
  }
</script>