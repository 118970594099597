<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu }">


          <v-btn
            icon
            v-on="{ ...menu }"
          >
            <img
              height='24px'
              contain="true"
              width="24px"
              :src="currentLanguageIcon"
              v-if="currentLanguageIcon"
            />
            <v-icon v-else>mdi-earth</v-icon>
          </v-btn>


    </template>
    <v-list>
      <v-list-item
        v-for="language in languages"
        :key="language.id"
        @click="changeLanguage(language.id)"
      >
        <v-list-item-avatar
          tile
          size="24"
        >
          <v-img :src="language.flagSrc"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ language.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { Trans } from '@/plugins/Translation'

export default {
  computed: {
    languages() {
      return Trans.supportedLocalesArray
    },
    currentLanguageIcon() {
      return this.languages.filter(x => x.id === this.$i18n.locale)[0]
        .flagSrc;
    }
  },
  methods: {
    changeLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: {locale} })
        return Trans.changeLocale(locale).then(() => { 
          this.$router.push(to.location)
        })
      }
    }
  }
};
</script>
