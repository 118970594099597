<template>
<v-app :style="cssVars">

    <v-app-bar app v-if="this.$router.currentRoute.name != 'survey'">
    
      <v-btn 
        icon 
        @click="$router.go(-1)" 
        v-if="this.$router.currentRoute.name != 'home' "
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="align-items-center d-flex mr-auto">
        <router-link :to="$i18nRoute({ name: 'home', params: { etab: etab }})"  style="cursor: pointer" class="align-items-center d-flex mr-auto">
        <img 
          class="ml-1"
          height="70px"
          :src="(content && content.logo) ? '/storage/app/media/'+content.logo : '/storage/app/media/Logotypes/LOGO-AR-HORIZONTAL-COULEUR.png'"
        >
        </router-link>
      </v-toolbar-title>
      <LangSwitcher />
      <v-btn v-if="content && content.phone" icon :href="`tel:${content.phone}`">
        <v-icon>mdi-phone</v-icon>
      </v-btn>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>      
    </v-app-bar>
    <v-app-bar color="white" flat prominent v-else class="" elevation-0 >
    <img   
      class="mx-auto pt-5 mb-4"
      style="align-self: center;justify-self: center;"
      height="150px"
      :src="(content && content.logo) ? '/storage/app/media/'+content.logo : '/storage/app/media/Logotypes/LOGO-AR-VERTICAL-COULEUR.png'"
    >
    </v-app-bar>
    <v-navigation-drawer  
      v-if="this.$router.currentRoute.name != 'survey'"
      v-model="drawer"
      fixed
      temporary
      right
      overlay-opacity="0.88"
    >
      <v-list-item >
        <v-list-item-content>
          <v-list-item-title v-if="content" v-text="content.name"></v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense v-if="content">
        <v-list-item
          
          v-for="item in content.pages"
          :key="item.title"
          :value="item.active"
          link
          :to="$i18nRoute({ name: 'page', params: { page: item.slug }})"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.nav_icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title >{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <BannerCovid  v-show="Covid" v-if="banner && this.$router.currentRoute.name != 'survey'" :banner="banner" />
      <v-banner v-show="searching"
        color="grey lighten-4"
        elevation="1"
        sticky
        app
        transition="expand-y-transition"
      >
      <v-text-field 
          ref="searchField"
          v-model="searchText" 
          prepend-inner-icon="mdi-magnify"
          hide-details 
          single-line
          clearable
          class="searchField"
        ></v-text-field>
      </v-banner>
      <transition name="fade" mode="out-in">
      <router-view class="view" ></router-view>
      </transition>
    </v-main>
    <v-footer app
      :padless="padless"
    >
      <v-card
        flat
        tile
        width="100%"
        class="grey lighten-3 text-center"
      >
        <v-card-text class="primary--text">
          {{ new Date().getFullYear() }} — <strong>Alpine Resorts</strong> <small class="text-muted">- v{{ $store.getters.appVersion }}</small>
        </v-card-text>
      </v-card>
    </v-footer>
    <CookieConsent />
    <v-bottom-navigation
      v-model="value"
      v-if="isKnown()"
      fixed
      exact
      horizontal
      
    >
      <v-btn exact value="precheckin" exact-active-class="white primary--text font-weight-bold"  :to="{name: 'precheckin'}">
        <span>Pré-checkin</span>

        <v-icon>mdi-clock-fast</v-icon>
      </v-btn>

      <v-btn exact value="home" exact-active-class="white primary--text font-weight-bold" :to="{name: 'home'}">
        <span>Livret d'accueil</span>

        <v-icon>mdi-book-open-variant</v-icon>
      </v-btn>
    </v-bottom-navigation>

  </v-app>
</template>
<style>
#app{
  width:100%;
}
.v-application .primary--text{
  color: var(--color-primary) !important;
}
.primary--bg{
  background-color: var(--color-primary) !important;
}
.router-link-exact-active{
  text-decoration: none;
}
.v-card__title{
  font-size:1rem!important;
  word-break: break-word!important;
  line-height: 1.2rem!important;
}
.v-text-field.searchField{
  margin-top:0;
  padding-top:0;
}

</style>
<script>
import BannerCovid from '@/components/BannerCovid.vue'
import LangSwitcher from '@/components/LangSwitcher.vue'
import CookieConsent from '@/components/CookieConsent.vue'
import axios from 'axios'

  export default {
    components: {
      BannerCovid,
      LangSwitcher,
      CookieConsent
    },
    async created () {
      await this.fetchData()
    },
    beforeMount () {
      this.checkAuth()
    },
    data () {
      return {
        etab: this.$route.params.etab,
        searchText: null,
        drawer: null,
        searching: false,
        Covid: false,
        padless: true,
        variant: 'default',
        content: null,
        loading: true,
        banner: null,
        value: this.$route.name,
        identifier: this.$route.params.identifier
      }
    },
    props: {
      colorPrimary: {
        type: String,
        default: "#4c5d44"
      }
    },
    watch: {
      // call again the method if the route changes
      '$route': 'fetchData',
    },
    computed: {
      cssVars() {
        return {
          "--color-primary": this.colorPrimary
        };
      }
    },
    methods: {
      searchToggle() {
        this.searching = !this.searching;
        if ( this.searching ) {
          this.$nextTick(() => this.$refs.searchField.focus());
        }
      },
      isKnown(){
        if(this.$session.get('authToken')){
          return true
        }
        else{ 
          return false
        }
      },
      checkAuth () {
        if(this.identifier){
          //verifier l'identifiant dans la bdd

          this.$session.start()
          this.$session.set('authToken',this.identifier)
        }
      },
      async fetchData () {
        this.error = this.content = null
        const that = this
        this.loading = true
        axios.interceptors.request.use(
        function (config) {
            config.headers['Accept-Language']=that.$route.params.locale
            return config;
          }
        );
        const response = await axios.get('/etab/' + this.etab)
        this.content =  response.data
        this.colorPrimary = (this.content.color) ? this.content.color : this.colorPrimary
        const rr = await axios.get('/banners/'+ this.etab)
        this.banner = rr.data
        this.loading = false
        
      }
    },
    mounted() {
      this.$store.dispatch('getEtabs')
      setInterval(() => {

        this.Covid = true
        
      },3000)
    }
    
  }
</script>

